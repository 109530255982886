export {
  getRandomIntInclusive,
  throttled,
  debounced,
  padLeft,
  padRight,
  getTodayString,
  getDisplayStringFromDate,
  getISOStringFromDate,
  getWeekDayStringFromDate,
  getDateFromISOString,
  getDateTimeFromISOString,
  getRoomsQueryString,
  getRoomsQueryDisplayString,
  getRoomsQueryFromString,
  newRoomQuery,
  newRoomsQuery,
  getWhereDisplayString,
  getEventTypesDisplayString,
  getPurposesDisplayString,
  deepCopy,
  encodeStringArray,
  newContentsQuery,
  pluralize,
  scrollToTop,
  getReservationResume,
  getAdultsChildrenDesc,
  formatCurrency,
}

function getRandomIntInclusive(min, max) {
  // The maximum is inclusive and the minimum is inclusive
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// see https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
// ES6 code
function throttled(delay, fn) {
  let lastCall = 0
  return function (...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}
// ES6
function debounced(delay, fn) {
  let timerId
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

function padLeft(s, filler, length) {
  let ret = s
  while (ret.length < length) {
    ret = filler + ret
  }
  return ret
}

function padRight(s, filler, length) {
  let ret = s
  while (ret.length < length) {
    ret = ret + filler
  }
  return ret
}

function getTodayString() {
  return getISOStringFromDate(new Date())
}

function getReservationResume(reservationData) {
  // result like: 2 Rooms - Tue, Jul 21 > Wed, Jul 22 - Milan, Babila Hostel
  const roomsS = pluralize([[reservationData.queries.length, 'Room', 'Rooms']])
  const datesS = `${getWeekDayStringFromDate(reservationData.from)} > ${getWeekDayStringFromDate(reservationData.to)}`
  const hotel = reservationData.hotel
  const hotelS = `${hotel.address.cityName}, ${hotel.name}`
  const ret = `${roomsS} - ${datesS} - ${hotelS}`
  return ret
}

function getWeekDayStringFromDate(date) {
  // Sat, Apr 25
  if (date === null) {
    return ''
  }
  const dayName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
  const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]
  return `${dayName}, ${monthName} ${date.getDate()}`
}

function getDisplayStringFromDate(date) {
  // 25-12-2019
  if (date === null) {
    return ''
  }
  const year = date.getFullYear().toString()
  const month = padLeft((date.getMonth() + 1).toString(), '0', 2)
  const day = padLeft(date.getDate().toString(), '0', 2)
  return day + '-' + month + '-' + year
}

function getISOStringFromDate(date) {
  // 2019-12-25
  if (date === null) {
    return ''
  }
  return date.getFullYear().toString() + '-' + padLeft((date.getMonth() + 1).toString(), '0', 2) + '-' + padLeft(date.getDate().toString(), '0', 2)
}

function getDateTimeFromISOString(s) {
  if (!s) {
    // console.log("getDateFromISOString, s is null or undefined")
    return null
  }
  s = s.trim()
  const ret = new Date(s)
  if (isNaN(ret)) {
    return null
  }
  return ret
}

function getDateFromISOString(s) {
  if (!s) {
    // console.log("getDateFromISOString, s is null or undefined")
    return null
  }
  s = s.trim()
  if (s.length !== 10) {
    console.log('getDateFromISOString, s.length:', s.length)
    return null
  }
  const ret = new Date(s + 'T00:00:00')
  // console.log("getDateFromISOString, ret:", ret)
  if (isNaN(ret)) {
    return null
  }
  return ret
}

function newRoomQuery() {
  return {
    adults: 1,
    children: [],
  }
}

function newRoomsQuery() {
  return {
    rooms: [newRoomQuery()],
  }
}

function newContentsQuery() {
  return {
    roomsQuery: newRoomsQuery(),
    where: [],
    from: null,
    to: null,
    eventTypes: [],
    purposes: [],
  }
}

function getRoomsQueryString(roomsQuery) {
  if (roomsQuery === null || roomsQuery === undefined || roomsQuery.rooms === null || roomsQuery.rooms === undefined) {
    return ''
  }
  const rooms = roomsQuery.rooms
  const retArr = []
  rooms.forEach((room) => {
    let s = 'a' + room.adults
    if (room.children !== undefined && room.children !== null) {
      room.children.forEach((child) => {
        s = s + 'c' + child.age
      })
    }
    retArr.push(s)
  })
  return retArr.join('')
}

// wordsArr is an array of triplets: number to evaluate, single word, plural word
//  [ [0, 'a', 'as'],[1, 'b', 'bs'] ]
// separator is the separator to use when multiple triplets are given
// if excludeZeroes, zero values are not emitted
function pluralize(wordsArr, separator = ', ', excludeZeroes = true) {
  const ret = []
  wordsArr.forEach((words) => {
    if (!excludeZeroes || words[0] !== 0) {
      ret.push(words[0].toString() + ' ' + (words[0] === 1 ? words[1] : words[2]))
    }
  })
  return ret.join(separator)
}

function getRoomsQueryDisplayString(roomsQuery) {
  if (roomsQuery === null || roomsQuery === undefined || roomsQuery.rooms === null || roomsQuery.rooms === undefined) {
    return 'No room selected'
  }
  let totAdults = 0
  let totChildren = 0
  roomsQuery.rooms.forEach((room) => {
    totAdults = totAdults + room.adults
    totChildren = totChildren + room.children.length
  })
  return pluralize(
    [
      [roomsQuery.rooms.length, 'Room', 'Rooms'],
      [totAdults, 'Adult', 'Adults'],
      [totChildren, 'Child', 'Children'],
    ],
    ', '
  )
}

function getRoomsQueryFromString(s) {
  // a1c2c4c8a1
  if (s === null || s.trim() === '') {
    return null
  }
  const rooms = []
  const roomsArr = s.trim().split('a')
  for (const room of roomsArr) {
    if (room === '') {
      continue
    }
    const roomItem = {
      adults: 0,
      children: [],
    }
    const childrenArr = room.split('c')
    let cIdx = 0
    for (const item of childrenArr) {
      if (cIdx === 0) {
        roomItem.adults = parseInt(item, 10)
      } else {
        roomItem.children.push({ age: parseInt(item, 10) })
      }
      cIdx++
    }
    rooms.push(roomItem)
  }
  const ret = {
    rooms: rooms,
  }
  return ret
}

function _getIDNameChildrenArrayDisplayString(choicesArray, lookupArray, allRet, voidRet = '', joinString = ', ') {
  if (choicesArray === null || choicesArray === undefined || choicesArray.length === 0) {
    return voidRet
  }
  if (lookupArray === null || lookupArray === undefined || lookupArray.length === 0) {
    return voidRet
  }
  const flatLookup = []
  lookupArray.forEach((item) => {
    flatLookup.push(item)
    if (item.children !== undefined && item.children !== null) {
      item.children.forEach((child) => flatLookup.push(child))
    }
  })
  if (choicesArray.length === flatLookup.length) {
    return allRet
  }
  return flatLookup
    .filter((item) => choicesArray.includes(item.id))
    .map((item) => item.name)
    .join(joinString)
}

function getWhereDisplayString(where, whereLookup) {
  return _getIDNameChildrenArrayDisplayString(where, whereLookup, 'Anywhere', 'Anywhere')
}

function getEventTypesDisplayString(eventTypes, eventTypesLookup) {
  return _getIDNameChildrenArrayDisplayString(eventTypes, eventTypesLookup, 'Any')
}

function getPurposesDisplayString(purposes, purposesLookup) {
  return _getIDNameChildrenArrayDisplayString(purposes, purposesLookup, 'Any')
}

function deepCopy(obj) {
  const stringified = JSON.stringify(obj)
  // console.log('deepCopy', obj, stringified)
  return JSON.parse(stringified)
}

function encodeStringArray(arr) {
  if (arr === null) {
    return null
  }
  return arr.map((item) => item.trim()).join(',')
}

function scrollToTop() {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

function getAdultsChildrenDesc(query) {
  const ret = pluralize(
    [
      [query.numberOfAdults, 'Adult', 'Adults'],
      [query.numberOfChildren, 'Child', 'Children'],
    ],
    ', '
  )
  return ret
}

function formatCurrency(amount, currency) {
  // see also https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
  let opAmt = amount >= 0 ? amount + 0.001 : amount - 0.001
  return currency + ' ' + opAmt.toLocaleString().slice(0, -1)
}
