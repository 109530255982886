import { getTodayString } from '../lib'

const axios = require('axios')

// export { APIClient }

export class APIClient {
  constructor() {
    this.ax = axios.create({
      // baseURL: 'https://some-domain.com/api/',
      timeout: 60000,
      // headers: { 'X-Custom-Header': 'foobar' }
    })
  }

  prepareParams(params) {
    let ret = params
    // ensure params is not null or undefined
    if (!params) {
      ret = {}
    }
    // send local date with every request
    const TODAY_STRING_PARAM = 'todayString'
    if (!ret[TODAY_STRING_PARAM]) {
      ret[TODAY_STRING_PARAM] = getTodayString()
    }
    return ret
  }

  encodeParamsIntoURI(params) {
    const pp = this.prepareParams(params)
    const str = []
    // if (pp.hasOwnProperty(p)) {
    for (const p in pp)
      if (Object.prototype.hasOwnProperty.call(pp, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(pp[p]))
      }
    return str.join('&')
  }

  async searchhe(searchParams) {
    let ret = null
    // for info see https://github.com/axios/axios#request-config
    const url = '/rest/searchhe'
    const params = this.prepareParams(searchParams)
    const config = {
      params: params,
    }
    console.log('search config:', config)
    try {
      ret = await this.ax.get(url, config)
      console.log('search response', ret)
    } catch (error) {
      console.log('error in axios...', config, error)
      throw error
    } finally {
      //
    }
    return ret
  }

  async authSaveUser(asuParams) {
    let ret = null
    // for info see https://github.com/axios/axios#request-config
    const url = '/rest/auth/saveUser'
    const params = asuParams || {}
    // console.log('authSaveUser params', params, 'url', url)
    try {
      ret = await this.ax.post(url, params)
      console.log('authSaveUser response', ret)
    } catch (error) {
      console.log('error in authSaveUser', url, params, error)
      throw error
    } finally {
      //
    }
    return ret
  }

  async previewCheckUser(cpuParams) {
    let ret = null
    const url = '/preview/check'
    const params = cpuParams || {}
    // console.log('previewCheckUser params', params, 'url', url)
    try {
      // withCredentials is needed in order to get cookies from the server and transmit them to the browser
      ret = await this.ax.post(url, params, { withCredentials: true })
      console.log('previewCheckUser response', ret)
    } catch (error) {
      console.log('error in previewCheckUser', url, params, error)
      throw error
    } finally {
      //
    }
    return ret
  }

  async hotelBookingGetUserInfo(hbguiParams) {
    let ret = null
    // for info see https://github.com/axios/axios#request-config
    const url = '/rest/bookh/getUserInfo'
    const params = hbguiParams || {}
    console.log('hotelBookingGetUserInfo params', params, 'url', url)
    try {
      ret = await this.ax.post(url, params)
      console.log('book userInfo response', ret)
    } catch (error) {
      console.log('error in hotelBookingGetUserInfo', url, params, error)
      throw error
    } finally {
      //
    }
    return ret
  }

  async hotelBookingGetProjects() {
    let ret = null
    // for info see https://github.com/axios/axios#request-config
    const url = '/rest/bookh/getProjects'
    try {
      ret = await this.ax.get(url)
      console.log('book getProjects response', ret)
    } catch (error) {
      console.log('error in hotelBookingGetProjects', url, error)
      throw error
    } finally {
      //
    }
    return ret
  }

  async hotelBookingBook(bookParams) {
    console.log('hotelBookingBook bookParams:', bookParams)
    let ret = null
    // for info see https://github.com/axios/axios#request-config
    const url = '/rest/bookh/book'
    try {
      ret = await this.ax.post(url, bookParams)
      console.log('book response', ret)
    } catch (error) {
      console.log('error in hotelBookingBook', bookParams, error)
      throw error
    } finally {
      //
    }
    return ret
  }

  async statusExternalIP() {
    let ret = null
    // for info see https://github.com/axios/axios#request-config
    const url = '/rest/status/externalIP'
    try {
      ret = await this.ax.get(url)
      console.log('statusExternalIP response', ret)
    } catch (error) {
      console.log('error in statusExternalIP', url, error)
      throw error
    } finally {
      //
    }
    return ret
  }
}
