// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app'
import 'firebase/auth'

function getFirebaseConfig() {
  const cfg = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  }
  return cfg
}

function getFirebaseSignInOptions() {
  const opts = []
  if (process.env.FIREBASE_SIGN_IN_MAIL_PASSWORD === '1') {
    opts.push({
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      requireDisplayName: false,
    })
  }
  if (process.env.FIREBASE_SIGN_IN_FACEBOOK === '1') {
    opts.push({
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    })
  }
  return opts
}

export { getFirebaseConfig, getFirebaseSignInOptions }
