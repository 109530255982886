import Vue from 'vue'

import firebase from 'firebase/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import { getFirebaseConfig, getFirebaseSignInOptions } from '../config'
import { APIClient } from '../services'

import '../../styles/style.scss'
import './../../../node_modules/firebaseui/dist/firebaseui.css'

const msgLoading = '... loading ...'
const msgSignInUp = 'Please, sign in or up!'
const msgCheckEmail = 'We sent you an email to validate your email address. Please look for it in your inbox and follow the link inside the message. See you later!'
const msgThankYou = 'Thank you for your interest in Human Journeys! We will let you know when the site is alive and kicking. Please, hold on!'
const msgError = 'Unfortunately, an error occurred: '

const CONTAINER_ID = 'firebaseUIContainer'
new Vue({
  data() {
    return {
      apiClient: null,
      message: msgLoading,
    }
  },
  created() {
    this.apiClient = new APIClient()
    // Initialize Firebase
    //console.log('firebaseConfig', getFirebaseConfig())
    firebase.initializeApp(getFirebaseConfig())
    console.log('firebase.initializeApp done')
    firebase.auth().languageCode = 'en_US'
    firebase.auth().onAuthStateChanged(
      // we need lambda func so that "this" refers to $vm inside the function
      (firebaseUser) => {
        //console.log('*** onAuthStateChanged, firebaseUser', firebaseUser)
        if (firebaseUser) {
          // user is logged in. check user status on server.
          this.message = msgLoading
          this.previewCheckUser(firebaseUser)
        } else {
          // user is not logged in. show login form.
          this.message = msgSignInUp
          this.startUI(this.getFirebaseUI())
        }
      },
      (error) => {
        //this.authLoading = false
        console.log('*** ERROR in onAuthStateChanged', error)
        this.message = msgError + error
      }
    )
    console.log('firebase.auth().onAuthStateChanged done')
  },
  computed: {
    containerID: {
      get() {
        return CONTAINER_ID
      },
    },
  },
  methods: {
    getFirebaseUI: function () {
      let ui = firebaseui.auth.AuthUI.getInstance()
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      return ui
    },
    startUI: function (ui) {
      const uiConfig = {
        signInOptions: getFirebaseSignInOptions(),
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            console.log('signInSuccessWithAuthResult, authResult', authResult)
            return false
          },
          signInFailure: (error) => {
            console.log('signInFailure, error', error)
          },
          uiShown: () => {
            // The widget is rendered.
            // Hide the loader.
          },
        },
        signInFlow: 'popup',
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        // Terms of service url.
        tosUrl: 'https://www.example.com/tos.pdf',
        // Privacy policy url.
        privacyPolicyUrl: 'https://www.example.com/pp.pdf',
      }
      ui.start('#' + this.containerID, uiConfig)
    },
    previewCheckUser: function (firebaseUser) {
      console.log('previewCheckUser start, firebaseUser', firebaseUser)
      if (!firebaseUser) {
        return
      }
      this.message = msgLoading
      firebaseUser
        .getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const params = {
            uid: firebaseUser.uid,
            idToken: idToken,
          }
          this.apiClient
            .previewCheckUser(params)
            .then((result) => {
              console.log('previewCheckUser, result', result)
              const emailVerified = result.data.emailVerified
              const success = result.data.success
              console.log('previewCheckUser success', success, 'emailVerified', emailVerified)
              if (success === true) {
                //if success, browser shall redirect to /
                const cookie = document.cookie
                console.log('previewCheckUser, document.cookie', cookie)
                //alert('previewCheckUser, redirecting to /')
                window.location.href = '/'
              } else if (!emailVerified) {
                firebaseUser
                  .sendEmailVerification()
                  .then(() => {
                    // Email sent
                    console.log('sendEmailVerification ok')
                    this.message = msgCheckEmail
                  })
                  .catch((error) => {
                    // An error happened
                    console.log('Error in ', error)
                    this.message = msgError + error
                  })
              } else {
                this.message = msgThankYou
              }
            })
            .catch((error) => {
              console.log('error in previewCheckUser', error)
              this.message = msgError + error
            })
            .finally(() => {
              //
            })
        })
        .catch((error) => {
          console.log('previewCheckUser, error', error)
          this.message = msgError + error
        })
    },
  },
}).$mount('#app')
